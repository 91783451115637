<template>
  <div>
    <template v-for="(item, index) in  heavyEquipmentObj.heavy_equipment_header">
      <v-row :key="index">
        <v-card  flat width="100%">
          <!-- <v-img width="100%" :height="$vuetify.breakpoint.xlOnly ? 650 : $vuetify.breakpoint.xsOnly ?  200 :  ''"
            :src="require(`../../../src/assets/images/heavy-equipment/img-1.png`)"
             class="d-flex align-center img-gradient-overlay-industries"> -->
          <v-img width="100%" :height="$vuetify.breakpoint.xlOnly ? 650 : $vuetify.breakpoint.xsOnly ?  200 :  ''"
            :src="item.industry_image" class="d-flex align-center img-gradient-overlay-industries">
              <v-container class="apply-index" :class="[ $vuetify.breakpoint.lgAndUp ? 'container-width-industies' : '']">
                <v-row justify="center" class="scale-in">
                  <v-col cols="12">
                    <h1 class="white--text font-weight-bold text-center" :class="$vuetify.breakpoint.xlOnly || $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.mdOnly  ? 'text-h1' : $vuetify.breakpoint.smOnly ? 'text-h2' : 'text-h5'">{{item.industry_title }}</h1>
                  </v-col>
                  <v-col cols="12">
                    <p class="text-subtitle-1 white--text text-center" :class="$vuetify.breakpoint.xsOnly ? 'px-3' : ''">{{ item.industry_description }}</p>
                  </v-col>
                </v-row>
              </v-container>
          </v-img>
        </v-card>
      </v-row>
    </template>
    <template v-for="(item, index) in heavyEquipmentObj.heavy_equipment">
      <v-row class="heavy-epuipment-background" :key=" `heavy-body-${index}`">
        <v-container :class="[ $vuetify.breakpoint.lgAndUp ? 'container-width-industies pa-10' : $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly ?  'pa-14' :'']">
          <v-row :class="$vuetify.breakpoint.xs ? 'apply-column mx-1' : ''">
            <v-col xs="12" lg="5" xl="6" md="6" sm="6" class="rotate-in">
              <v-card flat :height="$vuetify.breakpoint.lgAndUp ? 500 : ''" class="heavy-epuipment-background">
                <v-card-title class="font-weight-bold white--text mt-4"
                  :class="$vuetify.breakpoint.lgAndUp ? 'text-h4': ''">{{ item.heavy_title }}
                </v-card-title>
                  <v-card-text class="justify-text mt-4">
                    <span class="text-left white--text justify-text" :class="$vuetify.breakpoint.lgAndUp ? 'text-subtitle-1': ''">{{ item.heavy_description }}</span>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn class="rounded-pill no-text-transform transparent-element" :width=" $vuetify.breakpoint.xsOnly ? '150': '220'"
                      :samll=" $vuetify.breakpoint.mdAndDown"
                      :x-large=" $vuetify.breakpoint.lgAndUp"
                      @click="$router.push('/demo')"> Book a Demo</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col :class="[ $vuetify.breakpoint.smAndUp ? 'd-flex align-center rotate-in' : '']" xs="12" lg="7" xl="6" md="6" sm="6">
                  <v-img class="rounded-xl" contain :aspect-ratio="$vuetify.breakpoint.xsOnly ? '1' : '2'"
                    height="100%" :src="item.heavy_image"></v-img>
                </v-col>
              </v-row>
        </v-container>
      </v-row>
    </template>
    <v-row>
     <v-container class="mt-5" :class=" $vuetify.breakpoint.lgAndUp ? 'container-width pa-10' : $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly ?  'pa-14' :''">
      <v-row v-for="(item, index) in heavyEquipmentObj.heavy_equipment_content"
        class="d-flex justify-space-between" :key="`content-${index}`" :class="$vuetify.breakpoint.xsOnly ? 'make-column mx-1' : ''">
        <template v-if="item.direction === 'left' ||  $vuetify.breakpoint.mdAndDown">
          <v-col xl="6" lg="6" xs="12" md="12" sm="12" class=" d-flex align-center pr-0 reveal-card fade-bottom-card">
            <v-container>
              <v-card flat class=" justify-text">
                <v-row class="ma-0">
                  <p class="text-h4 text-left font-weight-bold">{{ item.title }}</p>
                  <span class="mt-4 align-text" style="line-height: 200%;">{{ item.rental_heavy_description }} </span>
                </v-row>
              </v-card>
            </v-container>
          </v-col>
          <v-col xl="6" lg="6" xs="12" md="12" sm="12" class="reveal-card fade-bottom-card pa-0">
            <v-img contain aspect-ratio="2" width="100%" :src="item.rental_heavy_image" class="d-flex align-cente mb-15 zoomable-image"></v-img>
          </v-col>
        </template>
        <template v-else-if="item.direction === 'right' && $vuetify.breakpoint.lgAndUp">
          <v-col xl="6" lg="6" xs="12" md="6" sm="12" class="pa-0 reveal-card fade-bottom-card">
            <v-img contain aspect-ratio="2" width="100%" :src="item.rental_heavy_image" class="d-flex align-center zoomable-image"></v-img>
          </v-col>
          <v-col xl="6" lg="6" xs="12" md="6" sm="12" class="d-flex align-center pl-0 reveal-card fade-bottom-card">
            <v-container>
              <v-card flat class="pa-5">
                <v-row class="ma-0">
                  <p class="text-h4 text-left font-weight-bold">{{ item.title }}</p>
                  <p class="mt-4 align-text" style="line-height: 200%;">{{ item.rental_heavy_description }} </p>
                </v-row>
              </v-card>
            </v-container>
          </v-col>
        </template>
      </v-row>
    </v-container>
    </v-row>
  </div>
</template>
<script>
import { api } from '../../plugins/axios'
import CommonFunctions from '@/mixins/animation.js'
export default {
  mixins: [CommonFunctions],
  data () {
    return {
      heavyEquipmentObj: {}
    }
  },
  mounted () {
    this.heavyEquipment()
    this.scrollToTop()
  },
  methods: {
    heavyEquipment () {
      api.get('/heavy_equipment')
        .then((response) => {
          if (response.data) {
            this.heavyEquipmentObj = response.data
            if (this.heavyEquipmentObj && this.heavyEquipmentObj.heavy_equipment_content) {
              this.heavyEquipmentObj.heavy_equipment_content = this.applyDirection(this.heavyEquipmentObj.heavy_equipment_content)
            }
          }
        })
        .catch((error) => {
          console.log(error.message)
          if (error.response && error.response.status === 500) {
            this.$router.push('/technical-difficulties')
          }
        })
    }
  }
}
</script>

<style>
.heavy-epuipment-background {
   background: #48424F !important;
}
.gradient-text-heavy-epuipment {
      background: linear-gradient(to right, #FFA726, #FFDAA4);
      color: #000000;
    }
.justify-text {
  text-align: justify !important;
  line-height: 1.8 !important;
}
 .no-text-transform {
    text-transform: none !important;
  }

.img-gradient-overlay-industries {
  position:relative;
}
.iimg-gradient-overlay-industries::after {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(194, 43, 214, 0.3),rgba(22, 18, 18, 0.6));
  border: 5px  dotted red !important;
}

@keyframes rotateIn {
  from {
    opacity: 0;
    transform: rotate(-45deg);
  }
  to {
    opacity: 1;
    transform: rotate(0);
  }
}

.rotate-in {
  animation: rotateIn 0.8s ease-out;
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.scale-in {
  animation: scaleIn 0.8s ease-out;
}
.container-width {
  width: 60% !important;
}
.zoomable-image {
  transition: transform 0.3s ease;
}

.zoomable-image:hover {
  transform: scale(1.2);
}
</style>
